import { t } from "i18next";
import { useCallback, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";

import { StatemachineContext } from "../../../../shared/components/statemachine/contexts/StatemachineContext";
import useCreateCallback from "../../../../shared/hooks/useCreateCallback";
import {
  CallbackErrorHandler,
  CallbackSuccessHandler,
} from "../../../../shared/hooks/useResourceCallback";
import { QuestionFlowContext } from "../../../../shared/lib/questionFlowContext";
import {
  ChatType,
  ResourceType,
  UserResource,
} from "../../../../types/serializers";
import EnterEmailStep from "../steps/EnterEmailStep";
import EnterNameStep from "../steps/EnterNameStep";
import QuestionReceivedStep from "../steps/QuestionReceivedStep";

const useCreateQuestion = (): [
  callback: (user: UserResource | undefined) => void,
  loading: boolean,
  error: string | undefined
] => {
  const navigate = useNavigate();
  const { currentJourney } = useContext(StatemachineContext);
  const {
    currentWizardId,
    moveForwards,
    referer,
    setForwardedChatConversationId,
    volunteerQuestion,
    wizardActive,
  } = useContext(QuestionFlowContext);
  const [createCallback, loading] = useCreateCallback(
    ResourceType.ChatConversation
  );
  const [error, setError] = useState<string | undefined>(undefined);

  const handleError: CallbackErrorHandler = ({ json }) => {
    if (json.errors && json.errors.length > 0) {
      setError(json.errors[0].title);
    } else {
      setError(t("shared:status.error")!);
    }
  };
  const handleChatConversationSuccess: CallbackSuccessHandler = ({ json }) => {
    setForwardedChatConversationId(json.data?.id);
    moveForwards(QuestionReceivedStep);
    navigate("/account");
  };
  const createChatConversation = useCallback(
    () =>
      createCallback(
        {
          chatType: ChatType.VolunteerChat,
          initialMessage: volunteerQuestion,
          journeyId: wizardActive ? currentJourney?.id : undefined,
          referer: referer,
          wizardId: currentWizardId,
        },
        handleChatConversationSuccess,
        handleError
      ),
    [volunteerQuestion, currentJourney?.id, referer]
  );

  const callback = useCallback(
    (user?: UserResource) => {
      setError(undefined);
      if (user) {
        if (user.attributes.firstname) {
          createChatConversation();
        } else {
          moveForwards(EnterNameStep);
        }
      } else {
        moveForwards(EnterEmailStep);
      }
    },
    [createChatConversation]
  );

  return [callback, loading, error];
};

export default useCreateQuestion;
