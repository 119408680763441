import React, { FC, PropsWithChildren, useState } from "react";

import { Context } from "../../../shared/components/statemachine/contexts/StatemachineContext";
import useCombinedReferer from "../../../shared/hooks/useCombinedReferer";
import {
  OpenFlowOpts,
  QuestionFlowContext,
} from "../../../shared/lib/questionFlowContext";
import { Prediction } from "../../../types/serializers";

import useQuestionFlowTracker from "./hooks/useQuestionFlowTracker";
import QuestionFlow, { QuestionFlowProps } from "./QuestionFlow";
import AskQuestionStep from "./steps/AskQuestionStep";
import ContinueOrRestartStep from "./steps/ContinueOrRestartStep";
import StartWizardStep from "./steps/StartWizardStep";
import WizardStep from "./steps/WizardStep";

export enum FlowDirection {
  Backwards,
  Forwards,
}

export type StepProps = Record<string, number>;

export const useQuestionFlowContext = <T,>({
  initialOpen,
  initialQuestion: initialQuestionProp,
  initialStep,
  initialStepProps,
  initialWizardId,
  widget,
}: {
  initialOpen?: boolean;
  initialQuestion?: string;
  initialStep?: FC<T>;
  initialStepProps?: T;
  initialWizardId?: string;
  widget?: boolean;
} = {}): [QuestionFlowContext, QuestionFlowProps] => {
  const [showFloat, setShowFloat] = useState(true);
  const [open, setOpen] = useState(initialOpen || false);
  const [referer, setReferer] = useState<string | undefined>(undefined);
  const [footerEnabled, setFooterEnabled] = useState<boolean>(false);
  const combinedReferer = useCombinedReferer(referer);
  const [initialQuestion, setInitialQuestion] = useState(
    initialQuestionProp ?? ""
  );
  const [forwardedChatConversationId, setForwardedChatConversationId] =
    useState<undefined | string>(undefined);
  const [volunteerQuestion, setVolunteerQuestion] = useState("");
  const [email, setEmail] = useState("");
  const [wizardActive, setWizardActive] = useState(!!initialWizardId);
  const [currentWizardId, setCurrentWizardId] = useState<string | undefined>(
    initialWizardId
  );
  const [recommendedWizards, setRecommendedWizards] = useState<Prediction[]>(
    []
  );
  const [iteration, setIteration] = useState(0);
  const [statemachineContext, setStatemachineContext] = useState(
    Context.QuestionFlow
  );
  const [currentStep, setCurrentStep] = useState<FC<any>>(
    () => initialStep ?? AskQuestionStep
  );
  const [currentStepProps, setCurrentStepProps] = useState<StepProps>(
    initialStepProps ?? {}
  );
  const [previousStep, setPreviousStep] = useState<FC<any> | undefined>(
    undefined
  );
  const [previousStepProps, setPreviousStepProps] = useState<StepProps>({});
  const [direction, setDirection] = useState<FlowDirection>(
    FlowDirection.Forwards
  );
  const [onBackwards, setOnBackwards] = useState<(() => void) | undefined>(
    undefined
  );
  const [onForwards, setOnForwards] = useState<(() => void) | undefined>(
    undefined
  );
  const [error, setError] = useState<string | undefined>(undefined);
  const [forwardDisabled, setForwardDisabled] = useState<boolean | undefined>(
    false
  );
  const [renderAsForm, setRenderAsForm] = useState(true);
  const [questionFlowId, setQuestionFlowId] = useState<string | undefined>(
    undefined
  );
  const resetFlow = () => {
    setQuestionFlowId(undefined);
    setWizardActive(false);
    setCurrentWizardId(undefined);
    setInitialQuestion("");
    setVolunteerQuestion("");
    moveForwards(AskQuestionStep);
    setStatemachineContext(Context.QuestionFlow);
  };
  const openFlow = ({
    context,
    referer: newReferer,
    restart,
    wizardId,
  }: OpenFlowOpts) => {
    if (wizardId) {
      if (wizardId === currentWizardId) {
        moveBackwards(WizardStep, { stepIndex: 1 });
      } else {
        resetFlow();
        setCurrentWizardId(wizardId);
        setCurrentStep(() => StartWizardStep);
      }
    } else if (
      currentStep !== AskQuestionStep &&
      currentStep !== ContinueOrRestartStep &&
      !restart
    ) {
      moveForwards(ContinueOrRestartStep);
    }

    if (context) {
      setStatemachineContext(context);
    }
    if (newReferer) {
      setReferer(newReferer);
    }
    setOpen(true);
  };

  const closeFlow = (reset?: boolean) => {
    if (reset) {
      resetFlow();
    }
    setIteration(0);
    setOpen(false);
  };

  const updateStep = <P,>(
    newDirection: FlowDirection,
    newStep: FC<P>,
    newStepProps?: P
  ) => {
    setFooterEnabled(false);
    setDirection(newDirection);
    setPreviousStep(() => currentStep);
    setPreviousStepProps(currentStepProps ?? {});
    setCurrentStep(() => newStep);
    setCurrentStepProps(newStepProps ?? {});
    setIteration((prev) => prev + 1);
  };

  const moveBackwards = <P,>(newStep?: FC<P>, newStepProps?: P) => {
    if (newStep || previousStep) {
      updateStep(
        FlowDirection.Backwards,
        newStep ?? previousStep!,
        newStepProps ?? previousStepProps
      );
    }
  };
  const moveForwards = <P,>(newStep: FC<P>, newStepProps?: P) => {
    updateStep(FlowDirection.Forwards, newStep, newStepProps);
  };

  return [
    {
      closeFlow,
      currentWizardId,
      email,
      error,
      footerEnabled,
      forwardDisabled,
      forwardedChatConversationId,
      initialQuestion,
      moveBackwards,
      moveForwards,
      onBackwards,
      onForwards,
      open,
      openFlow,
      questionFlowId,
      recommendedWizards,
      referer: combinedReferer,
      renderAsForm,
      resetFlow,
      setCurrentWizardId,
      setEmail,
      setError,
      setFooterEnabled,
      setForwardDisabled,
      setForwardedChatConversationId,
      setInitialQuestion,
      setOnBackwards,
      setOnForwards,
      setQuestionFlowId,
      setRecommendedWizards,
      setRenderAsForm,
      setShowFloat,
      setVolunteerQuestion,
      setWizardActive,
      showFloat,
      statemachineContext,
      volunteerQuestion,
      widget,
      wizardActive,
    },
    {
      currentStep,
      currentStepProps,
      direction,
      iteration,
      previousStep,
      previousStepProps,
    },
  ];
};

const QuestionFlowProvider: FC<PropsWithChildren> = ({ children }) => {
  const [context, props] = useQuestionFlowContext();
  useQuestionFlowTracker(context, props);

  return (
    <QuestionFlowContext.Provider value={context}>
      {children}
      <QuestionFlow {...props} />
    </QuestionFlowContext.Provider>
  );
};

export default QuestionFlowProvider;
