import { FormControl, FormLabel, Input, Stack } from "@chakra-ui/react";
import { t } from "i18next";
import React, { FC, useCallback, useContext, useState } from "react";

import { QuestionFlowContext } from "../../../../shared/lib/questionFlowContext";
import { UserResource } from "../../../../types/serializers";
import { useVerifyPassword } from "../../../lib/auth";
import RequestPasswordReset from "../../auth/RequestPasswordReset";
import useCreateQuestion from "../hooks/useCreateQuestion";
import useDelayedFocus from "../hooks/useDelayedFocus";
import useFlowOptions from "../hooks/useFlowOptions";
import { StepRendererProps } from "../QuestionFlow";

import EnterEmailStep from "./EnterEmailStep";

const EnterPasswordStep: FC<StepRendererProps> = ({ active }) => {
  const { email, moveBackwards } = useContext(QuestionFlowContext);
  const [password, setPassword] = useState("");
  const [submitQuestion, createLoading, createError] = useCreateQuestion();
  const ref = useDelayedFocus<HTMLInputElement>();
  const handleSuccess = useCallback((user: UserResource | undefined) => {
    submitQuestion(user);
  }, []);
  const [handleSubmit, loading, error] = useVerifyPassword(
    email,
    password,
    false,
    handleSuccess
  );
  const handleBackwards = useCallback(() => moveBackwards(EnterEmailStep), []);
  useFlowOptions(active, {
    error: error ?? createError,
    forwardDisabled: loading || createLoading,
    onBackwards: handleBackwards,
    onForwards: handleSubmit,
  });

  return (
    <Stack height="100%">
      <FormControl>
        <FormLabel fontWeight="bold">
          {t("main:questionFlow.enterPasswordStep.inputs.label")}
        </FormLabel>
        <Input
          required
          placeholder={
            t("main:questionFlow.enterPasswordStep.inputs.placeholder")!
          }
          ref={ref}
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <RequestPasswordReset email={email} float="right" />
      </FormControl>
    </Stack>
  );
};

export default EnterPasswordStep;
