import { Stack, Text, Textarea } from "@chakra-ui/react";
import { t } from "i18next";
import React, { FC, useCallback, useContext, useState } from "react";

import useCreateCallback from "../../../../shared/hooks/useCreateCallback";
import { CallbackSuccessHandler } from "../../../../shared/hooks/useResourceCallback";
import { trackEvent } from "../../../../shared/lib/analytics";
import { QuestionFlowContext } from "../../../../shared/lib/questionFlowContext";
import { ResourceType } from "../../../../types/serializers";
import { TooltipToggle } from "../../Chat/components/TooltipToggle";
import useDelayedFocus from "../hooks/useDelayedFocus";
import useFlowOptions from "../hooks/useFlowOptions";
import { StepRendererProps } from "../QuestionFlow";

import ChooseWizardStep from "./ChooseWizardStep";
import NoMatchesStep from "./NoMatchesStep";

const MIN_SCORE = 0.2;
const MAX_WIZARDS = 3;

const AskQuestionStep: FC<StepRendererProps> = ({ active }) => {
  const {
    moveForwards,
    initialQuestion,
    setInitialQuestion,
    setRecommendedWizards,
    setCurrentWizardId,
  } = useContext(QuestionFlowContext);
  const ref = useDelayedFocus<HTMLTextAreaElement>(active);
  const [error, setError] = useState<undefined | string>(undefined);
  const [classifyCallback, classificationLoading] = useCreateCallback(
    ResourceType.ClassifierResult,
    GLOBALS.statemachineRoot
  );

  const handleError = () => setError(t("shared:statemachine.errorMessage")!);
  const handleSuccess: CallbackSuccessHandler<
    ResourceType.ClassifierResult
  > = ({ json }) => {
    setCurrentWizardId(undefined);
    trackEvent("question_flow_question_asked");
    const { prediction } = json.data?.attributes ?? {};
    const filteredPrediction = (prediction ?? []).filter(
      ({ score }) => score > MIN_SCORE
    );

    if (filteredPrediction.length > 0) {
      setRecommendedWizards(filteredPrediction.slice(0, MAX_WIZARDS));
      moveForwards(ChooseWizardStep);
    } else {
      setRecommendedWizards([]);
      moveForwards(NoMatchesStep);
    }
  };

  const submitQuestion = useCallback(() => {
    classifyCallback({ text: initialQuestion }, handleSuccess, handleError);
  }, [initialQuestion]);
  useFlowOptions(active, {
    error,
    forwardDisabled: classificationLoading,
    onForwards: submitQuestion,
  });

  return (
    <Stack height="100%">
      <Textarea
        required
        height="100%"
        minLength={3}
        placeholder={t("main:questionFlow.askQuestionStep.input.placeholder")!}
        ref={ref}
        value={initialQuestion}
        onChange={(e) => setInitialQuestion(e.target.value)}
      />
      <Text fontSize="12px" fontWeight={300}>
        <Text>
          {t("main:questionFlow.askQuestionStep.disclaimer_1")}{" "}
          <TooltipToggle
            label={t("main:questionFlow.askQuestionStep.disclaimer_tooltip")}
            trackingKeyword={t(
              "main:questionFlow.askQuestionStep.disclaimer_trackingKeyword"
            )}
          />
        </Text>

        {t("main:questionFlow.askQuestionStep.disclaimer_2")}
      </Text>
    </Stack>
  );
};

export default AskQuestionStep;
