import { useRollbar } from "@rollbar/react";
import { FC, useEffect, useState } from "react";

import useCreateCallback from "../../../../shared/hooks/useCreateCallback";
import useResource from "../../../../shared/hooks/useResource";
import { CallbackSuccessHandler } from "../../../../shared/hooks/useResourceCallback";
import useUpdateCallback from "../../../../shared/hooks/useUpdateCallback";
import { QuestionFlowContext } from "../../../../shared/lib/questionFlowContext";
import { ResourceType } from "../../../../types/serializers";
import { QuestionFlowProps } from "../QuestionFlow";
import AskQuestionStep from "../steps/AskQuestionStep";
import ContinueOrRestartStep from "../steps/ContinueOrRestartStep";
import QuestionReceivedStep from "../steps/QuestionReceivedStep";

const shouldTrackProgress = (currentStep: FC) =>
  ![AskQuestionStep, ContinueOrRestartStep].includes(currentStep);

const useQuestionFlowTracker = (
  context: QuestionFlowContext,
  props: QuestionFlowProps
) => {
  const {
    currentWizardId,
    initialQuestion,
    forwardedChatConversationId,
    volunteerQuestion,
    questionFlowId,
    referer,
    setQuestionFlowId,
    statemachineContext,
  } = context;
  const { currentStep } = props;
  const rollbar = useRollbar();
  const [createQuestionFlow, createPending] = useCreateCallback(
    ResourceType.QuestionFlow
  );
  const [updateQuestionFlow] = useUpdateCallback(
    questionFlowId
      ? { id: questionFlowId, type: ResourceType.QuestionFlow }
      : undefined
  );
  const questionFlow = useResource({
    id: questionFlowId,
    type: ResourceType.QuestionFlow,
  });
  const [wizardIds, setWizardIds] = useState<number[]>([]);

  useEffect(() => {
    setWizardIds(currentWizardId ? [parseInt(currentWizardId)] : []);
  }, [questionFlowId]);

  useEffect(() => {
    if (currentWizardId) {
      setWizardIds([...wizardIds, parseInt(currentWizardId)]);
    }
  }, [currentWizardId]);

  const forwardedQuestion =
    currentStep == QuestionReceivedStep
      ? volunteerQuestion || questionFlow?.attributes.forwardedQuestion
      : undefined;

  const handleSuccess: CallbackSuccessHandler = ({ json }) => {
    if (json.data) {
      setQuestionFlowId(json.data.id);
    }
  };
  const handleError = () => {
    rollbar.error("Failed to track question flow");
  };

  useEffect(() => {
    if (shouldTrackProgress(currentStep)) {
      const questionFlowProps = {
        context: statemachineContext,
        current_step: currentStep.displayName ?? currentStep.name,
        forwarded_chat_conversation_id: forwardedChatConversationId,
        forwarded_question: forwardedQuestion,
        initial_question:
          initialQuestion || questionFlow?.attributes.initialQuestion,
        referer,
        wizard_ids: wizardIds,
      };

      if (updateQuestionFlow) {
        updateQuestionFlow(questionFlowProps, undefined, handleError);
      } else if (!createPending) {
        createQuestionFlow(questionFlowProps, handleSuccess, handleError);
      }
    }
  }, [currentStep, wizardIds]);
};

export default useQuestionFlowTracker;
