import { Box } from "@chakra-ui/react";
import { t } from "i18next";
import React, { FC, useContext } from "react";
import { FiArrowLeft } from "react-icons/fi";

import Button from "../../../../shared/components/button";
import VolunteerButton from "../../../../shared/components/button/VolunteerButton";
import SimpleMarkdown from "../../../../shared/components/simpleMarkdown";
import { QuestionFlowContext } from "../../../../shared/lib/questionFlowContext";
import useFlowOptions from "../hooks/useFlowOptions";
import { StepRendererProps } from "../QuestionFlow";

import AskQuestionStep from "./AskQuestionStep";
import ReformulateQuestionStep from "./ReformulateQuestionStep";

const NoMatchesStep: FC<StepRendererProps> = ({ active }) => {
  const { initialQuestion, moveBackwards, moveForwards, setVolunteerQuestion } =
    useContext(QuestionFlowContext);
  const handleForwards = () => {
    moveForwards(ReformulateQuestionStep);
    setVolunteerQuestion(initialQuestion);
  };
  useFlowOptions(active);

  return (
    <Box>
      <Box marginBottom="1em">
        <SimpleMarkdown>
          {t("main:questionFlow.noMatchesStep.description")!}
        </SimpleMarkdown>
      </Box>
      <Button
        leftIcon={<FiArrowLeft />}
        marginY="0.2em"
        variant="outline"
        width="100%"
        onClick={() => moveBackwards(AskQuestionStep)}
      >
        {t("main:questionFlow.noMatchesStep.buttons.askAgain")}
      </Button>
      <VolunteerButton
        text={t("main:questionFlow.noMatchesStep.buttons.guide")}
        onClick={handleForwards}
      />
    </Box>
  );
};

export default NoMatchesStep;
