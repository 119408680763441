import { useContext, useEffect } from "react";

import { QuestionFlowContext } from "../../../../shared/lib/questionFlowContext";

interface FooterOptionsProps {
  error?: string;
  forwardDisabled?: boolean;
  onBackwards?: () => void;
  onForwards?: () => void;
  renderAsForm?: boolean;
}
const useFlowOptions = (
  active?: boolean,
  {
    error,
    forwardDisabled,
    onBackwards,
    onForwards,
    renderAsForm,
  }: FooterOptionsProps = {}
) => {
  const {
    setOnBackwards,
    setOnForwards,
    setError,
    setFooterEnabled,
    setForwardDisabled,
    setRenderAsForm,
  } = useContext(QuestionFlowContext);
  useEffect(() => {
    if (active) {
      setOnBackwards(() => onBackwards);
    }
  }, [active, onBackwards]);
  useEffect(() => {
    if (active) {
      setOnForwards(() => onForwards);
    }
  }, [active, onForwards]);
  useEffect(() => {
    if (active) {
      setError(error);
    }
  }, [active, error]);
  useEffect(() => {
    if (active) {
      setForwardDisabled(forwardDisabled);
    }
  }, [active, forwardDisabled]);
  useEffect(() => {
    if (active) {
      setRenderAsForm(renderAsForm !== false);
    }
  }, [active, renderAsForm]);
  useEffect(() => {
    if (active) {
      setFooterEnabled(true);
    }
  }, [active, onBackwards, onForwards]);
};

export default useFlowOptions;
