import { Box, IconButton, Tooltip, useDisclosure } from "@chakra-ui/react";
import React from "react";
import { FiInfo } from "react-icons/fi";

import { trackEvent } from "../../../../shared/lib/analytics";

interface TooltipToggleProps {
  label: string;
  trackingKeyword: string;
}

export const TooltipToggle: React.FC<TooltipToggleProps> = ({
  label,
  trackingKeyword,
}) => {
  const { isOpen, onOpen, onClose, onToggle } = useDisclosure();
  const handleTooltipOpen = () => {
    trackEvent("user_uses_tooltip", {
      keyword: trackingKeyword,
    });
  };

  const handleToggleAndTrack = () => {
    if (!isOpen) {
      handleTooltipOpen();
    }
    onToggle();
  };

  return (
    <Tooltip
      hasArrow
      bg="#FFFFFF"
      borderRadius="8px"
      boxShadow="md"
      color="blackish"
      fontSize="16px"
      isOpen={isOpen}
      label={label}
      padding={4}
      placement="top"
      onOpen={handleTooltipOpen}
    >
      <Box as="span" display="inline-flex" verticalAlign="middle">
        <IconButton
          aria-label="Tooltip"
          height="auto"
          icon={<FiInfo color="#0537FF" cursor="pointer" size={18} />}
          minHeight="auto"
          minW="none"
          variant="unstyled"
          onClick={handleToggleAndTrack}
          onMouseEnter={onOpen}
          onMouseLeave={onClose}
        />
      </Box>
    </Tooltip>
  );
};
