import { Box } from "@chakra-ui/react";
import React, {
  FC,
  FormEvent,
  PropsWithChildren,
  useContext,
  useEffect,
  useState,
} from "react";

import { QuestionFlowContext } from "../../../shared/lib/questionFlowContext";

import FooterButtons from "./FooterButtons";

interface StepWrapperProps extends PropsWithChildren {
  initialOut: boolean;
  out: boolean;
  sign: "+" | "-";
}

const StepInner = ({ children, initialOut, out, sign }: StepWrapperProps) => {
  const [currentOut, setCurrentOut] = useState(initialOut);
  useEffect(() => {
    setCurrentOut(out);
  }, [children]);
  const translateX = currentOut ? `${sign}100% ${sign} 2em` : "0";

  return (
    <Box
      aria-current={out ? false : "step"}
      bottom="3em"
      left={0}
      overflow="auto"
      position="absolute"
      right={0}
      top={0}
      transform={`translateX(calc(${translateX}))`}
      transition="transform .2s"
    >
      {children}
    </Box>
  );
};

const StepWrapper: FC<StepWrapperProps> = ({
  children,
  initialOut,
  out,
  sign,
}) => {
  const { onForwards, renderAsForm } = useContext(QuestionFlowContext);
  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    if (onForwards) {
      onForwards();
    }
  };

  return (
    <Box
      as={!out && renderAsForm ? "form" : undefined}
      bottom={0}
      display="flex"
      flexDirection="column"
      left={0}
      overflow="hidden"
      position="absolute"
      right={0}
      top={0}
      onSubmit={out ? undefined : handleSubmit}
    >
      <StepInner initialOut={initialOut} out={out} sign={sign}>
        {children}
      </StepInner>
      {!out && <FooterButtons />}
    </Box>
  );
};

export default StepWrapper;
