import { useEffect, useRef } from "react";

const DELAY = 500;

const useDelayedFocus = <T extends HTMLElement>(active?: boolean) => {
  const ref = useRef<T>(null);
  useEffect(() => {
    if (active && ref.current) {
      setTimeout(() => ref.current?.focus(), DELAY);
    }
  }, [ref.current]);

  return ref;
};

export default useDelayedFocus;
