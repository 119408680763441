import { Button, Heading, Stack } from "@chakra-ui/react";
import { t } from "i18next";
import React, { FC, useContext } from "react";

import { QuestionFlowContext } from "../../../../shared/lib/questionFlowContext";
import useFlowOptions from "../hooks/useFlowOptions";
import { StepRendererProps } from "../QuestionFlow";

import AskQuestionStep from "./AskQuestionStep";

const ContinueOrRestartStep: FC<StepRendererProps> = ({ active }) => {
  const { moveBackwards, moveForwards, resetFlow } =
    useContext(QuestionFlowContext);

  useFlowOptions(active);

  const handleContinue = () => {
    moveBackwards();
  };
  const handleRestart = () => {
    resetFlow();
    moveForwards(AskQuestionStep);
  };

  return (
    <Stack height="100%">
      <Heading as="h3" marginBottom="1em" size="sm">
        {t("main:questionFlow.ContinueOrRestartStep.header")}
      </Heading>
      <Button
        justifyContent="flex-start"
        variant="outline"
        width="100%"
        onClick={handleContinue}
      >
        {t("main:questionFlow.ContinueOrRestartStep.buttons.continue")}
      </Button>
      <Button
        justifyContent="flex-start"
        variant="outline"
        width="100%"
        onClick={handleRestart}
      >
        {t("main:questionFlow.ContinueOrRestartStep.buttons.restart")}
      </Button>
    </Stack>
  );
};

export default ContinueOrRestartStep;
