import {
  Box,
  FormControl,
  Heading,
  PinInput,
  PinInputField,
  Spinner,
  Stack,
  Text,
} from "@chakra-ui/react";
import { t } from "i18next";
import React, { FC, useCallback, useContext, useEffect, useState } from "react";

import { QuestionFlowContext } from "../../../../shared/lib/questionFlowContext";
import { UserResource } from "../../../../types/serializers";
import { useVerifyAuthCode } from "../../../lib/auth";
import RequestNewCode from "../../auth/RequestNewCode";
import useCreateQuestion from "../hooks/useCreateQuestion";
import useDelayedFocus from "../hooks/useDelayedFocus";
import useFlowOptions from "../hooks/useFlowOptions";
import { StepRendererProps } from "../QuestionFlow";

import EnterEmailStep from "./EnterEmailStep";

const CODE_LENGTH = 6;

const EnterOTPStep: FC<StepRendererProps> = ({ active }) => {
  const { email, moveBackwards } = useContext(QuestionFlowContext);
  const [authCode, setAuthCode] = useState("");
  const [submitQuestion, createLoading, createError] = useCreateQuestion();
  const ref = useDelayedFocus<HTMLInputElement>(active);
  const handleSuccess = useCallback((user: UserResource | undefined) => {
    submitQuestion(user);
  }, []);
  const [handleSubmit, loading, error] = useVerifyAuthCode(
    email,
    authCode,
    handleSuccess
  );
  const handleBackwards = useCallback(() => moveBackwards(EnterEmailStep), []);
  useFlowOptions(active, {
    error: error ?? createError,
    onBackwards: handleBackwards,
  });
  useEffect(() => {
    if (authCode.length === CODE_LENGTH) {
      handleSubmit();
    }
  }, [authCode]);

  return (
    <Stack height="100%">
      <Heading as="h3" marginBottom="1em" size="sm">
        {t("main:questionFlow.enterOTPStep.header")}
      </Heading>
      <Text>{t("main:questionFlow.enterOTPStep.description")}</Text>
      <FormControl>
        <Box display="flex" justifyContent="space-around" marginTop="1em">
          <PinInput
            otp
            value={authCode}
            onChange={(value) => {
              setAuthCode(value);
            }}
          >
            <PinInputField ref={ref} />
            <PinInputField />
            <PinInputField />
            <PinInputField />
            <PinInputField />
            <PinInputField />
          </PinInput>
          {(loading || createLoading) && <Spinner />}
        </Box>
        <RequestNewCode email={email} float="right" />
      </FormControl>
    </Stack>
  );
};

export default EnterOTPStep;
