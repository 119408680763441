import { Box, Flex, IconButton, Text } from "@chakra-ui/react";
import { t } from "i18next";
import React, { FC, Fragment, useContext } from "react";
import { FiArrowLeft, FiArrowRight } from "react-icons/fi";

import { QuestionFlowContext } from "../../../shared/lib/questionFlowContext";

const FooterButtons: FC = () => {
  const {
    error,
    forwardDisabled,
    footerEnabled,
    onBackwards,
    onForwards,
    renderAsForm,
  } = useContext(QuestionFlowContext);

  return (
    <Fragment>
      <Box flexGrow={1} />
      <Flex alignItems="center" justifyContent="space-between">
        {onBackwards ? (
          <IconButton
            aria-label={t("main:questionFlow.goBack")!}
            icon={<FiArrowLeft />}
            isDisabled={!footerEnabled}
            minWidth="3.5em"
            variant="outline"
            onClick={onBackwards}
          />
        ) : (
          <div />
        )}
        {error && (
          <Text color="status.error" fontSize="sm" paddingX="1em">
            {error}
          </Text>
        )}
        {onForwards && (
          <IconButton
            aria-label={t("main:questionFlow.continue")!}
            icon={<FiArrowRight />}
            isDisabled={!footerEnabled || forwardDisabled}
            minWidth="3.5em"
            type="submit"
            onClick={renderAsForm ? undefined : onForwards}
          />
        )}
      </Flex>
    </Fragment>
  );
};

export default FooterButtons;
