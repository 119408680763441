import { SkeletonText, Stack } from "@chakra-ui/react";
import React, { FC, useContext, useEffect } from "react";

import useStartJourney from "../../../../shared/components/statemachine/hooks/useStartJourney";
import { JourneyTrigger } from "../../../../shared/components/statemachine/types";
import { QuestionFlowContext } from "../../../../shared/lib/questionFlowContext";
import useFlowOptions from "../hooks/useFlowOptions";
import { StepRendererProps } from "../QuestionFlow";

const StartWizardStep: FC<StepRendererProps> = ({ active }) => {
  const { currentWizardId } = useContext(QuestionFlowContext);

  const [startJourney, _loading, error] = useStartJourney(
    currentWizardId ? parseInt(currentWizardId) : undefined
  );
  useEffect(() => {
    if (active) {
      startJourney(JourneyTrigger.Initial)();
    }
  }, []);

  useFlowOptions(active, {
    error,
  });

  return (
    <Stack height="100%">
      <SkeletonText />
    </Stack>
  );
};

export default StartWizardStep;
