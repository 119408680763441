import { Box, Button, Heading, Stack } from "@chakra-ui/react";
import { t } from "i18next";
import React, { FC, useCallback, useContext, useEffect, useState } from "react";

import VolunteerButton from "../../../../shared/components/button/VolunteerButton";
import useStartJourney from "../../../../shared/components/statemachine/hooks/useStartJourney";
import { JourneyTrigger } from "../../../../shared/components/statemachine/types";
import useResource from "../../../../shared/hooks/useResource";
import { QuestionFlowContext } from "../../../../shared/lib/questionFlowContext";
import { ResourceType } from "../../../../types/serializers";
import useFlowOptions from "../hooks/useFlowOptions";
import { StepRendererProps } from "../QuestionFlow";

import AskQuestionStep from "./AskQuestionStep";
import ReformulateQuestionStep from "./ReformulateQuestionStep";
import WizardStep from "./WizardStep";

const WizardButton: FC<{
  setError: (error?: string) => void;
  wizardId: string;
}> = ({ setError, wizardId }) => {
  const wizard = useResource(
    { id: wizardId, type: ResourceType.Wizard },
    { root: GLOBALS.statemachineRoot }
  );
  const [startJourney, loading, error] = useStartJourney(parseInt(wizardId));
  useEffect(() => {
    setError(error);
  }, [error]);

  return (
    <Button
      background="background.accordion"
      color="primary.darkActive"
      height="auto"
      isDisabled={loading}
      justifyContent="flex-start"
      minHeight="54px"
      paddingY="0.5rem"
      sx={{
        "&:hover": {
          background: "primary.100",
        },
      }}
      textAlign="left"
      whiteSpace="normal"
      onClick={startJourney(JourneyTrigger.Initial)}
    >
      {wizard?.attributes.question}
    </Button>
  );
};

const ChooseWizard: FC<StepRendererProps> = ({ active }) => {
  const {
    initialQuestion,
    moveBackwards,
    moveForwards,
    currentWizardId,
    recommendedWizards,
    setVolunteerQuestion,
    setWizardActive,
  } = useContext(QuestionFlowContext);
  const [error, setError] = useState<string | undefined>(undefined);

  const handleForward = useCallback(() => {
    setWizardActive(true);
    moveForwards(WizardStep, { stepIndex: 1 });
  }, []);
  const handleBackwards = useCallback(() => moveBackwards(AskQuestionStep), []);

  useFlowOptions(active, {
    error,
    onBackwards: handleBackwards,
    onForwards: currentWizardId ? handleForward : undefined,
  });

  const handleReformulate = () => {
    moveForwards(ReformulateQuestionStep);
    setVolunteerQuestion(initialQuestion);
  };

  return (
    <Stack height="100%">
      <Heading as="h3" marginBottom="1em" size="sm">
        {t("main:questionFlow.chooseWizardStep.header")}
      </Heading>
      {recommendedWizards.map((prediction) => (
        <WizardButton
          key={prediction.id}
          setError={setError}
          wizardId={prediction.id}
        />
      ))}
      <Box>
        <VolunteerButton
          text={t("main:questionFlow.chooseWizardStep.buttons.guide")}
          onClick={handleReformulate}
        />
      </Box>
    </Stack>
  );
};

export default ChooseWizard;
