import {
  FormControl,
  FormLabel,
  Heading,
  Input,
  Stack,
  Text,
} from "@chakra-ui/react";
import { t } from "i18next";
import React, { FC, useCallback, useContext } from "react";

import { QuestionFlowContext } from "../../../../shared/lib/questionFlowContext";
import { useRequestAuthCode } from "../../../lib/auth";
import useDelayedFocus from "../hooks/useDelayedFocus";
import useFlowOptions from "../hooks/useFlowOptions";
import { StepRendererProps } from "../QuestionFlow";

import EnterOTPStep from "./EnterOTPStep";
import EnterPasswordStep from "./EnterPasswordStep";
import ReformulateQuestionStep from "./ReformulateQuestionStep";
import SendToGuideStep from "./SendToGuideStep";

const EnterEmailStep: FC<StepRendererProps> = ({ active }) => {
  const { email, moveBackwards, moveForwards, setEmail, wizardActive } =
    useContext(QuestionFlowContext);
  const ref = useDelayedFocus<HTMLInputElement>(active);
  const handleSuccess = useCallback(() => moveForwards(EnterOTPStep), []);
  const handlePasswordRequired = useCallback(
    () => moveForwards(EnterPasswordStep),
    []
  );
  const [handleSubmit, loading, error] = useRequestAuthCode(
    email,
    handleSuccess,
    handlePasswordRequired
  );
  const questionStep = wizardActive ? SendToGuideStep : ReformulateQuestionStep;
  const handleBackwards = useCallback(
    () => moveBackwards(questionStep),
    [questionStep]
  );

  useFlowOptions(active, {
    error,
    forwardDisabled: loading,
    onBackwards: handleBackwards,
    onForwards: handleSubmit,
  });

  return (
    <Stack height="100%">
      <Heading as="h3" marginBottom="1em" size="sm">
        {t("main:questionFlow.enterEmailStep.header")}
      </Heading>
      <Text>{t("main:questionFlow.enterEmailStep.description")}</Text>
      <FormControl>
        <FormLabel fontWeight="bold">
          {t("main:questionFlow.enterEmailStep.input.label")}
        </FormLabel>
        <Input
          required
          placeholder={t("main:questionFlow.enterEmailStep.input.placeholder")!}
          ref={ref}
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
      </FormControl>
    </Stack>
  );
};

export default EnterEmailStep;
