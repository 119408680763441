import { Stack, Text, Textarea } from "@chakra-ui/react";
import { t } from "i18next";
import React, { FC, useCallback, useContext, useState } from "react";

import useCurrentUser from "../../../../shared/hooks/useCurrentUser";
import { trackEvent } from "../../../../shared/lib/analytics";
import {
  MIN_QUESTION_LENGTH,
  QuestionFlowContext,
} from "../../../../shared/lib/questionFlowContext";
import useCreateQuestion from "../hooks/useCreateQuestion";
import useDelayedFocus from "../hooks/useDelayedFocus";
import useFlowOptions from "../hooks/useFlowOptions";
import { StepRendererProps } from "../QuestionFlow";

import ChooseWizard from "./ChooseWizardStep";
import NoMatchesStep from "./NoMatchesStep";

const ReformulateQuestionStep: FC<
  StepRendererProps & {
    error?: string;
  }
> = ({ active, error: errorFromProp }) => {
  const [currentUser] = useCurrentUser();
  const {
    moveBackwards,
    recommendedWizards,
    setVolunteerQuestion,
    volunteerQuestion,
  } = useContext(QuestionFlowContext);
  const [error, setError] = useState(errorFromProp);
  const [submitQuestion, loading, submissionError] = useCreateQuestion();
  const ref = useDelayedFocus<HTMLTextAreaElement>(active);

  const handleForwards = useCallback(() => {
    trackEvent("question_forwarded");
    setError(undefined);
    submitQuestion(currentUser);
  }, [submitQuestion, currentUser]);
  const handleBack = useCallback(() => {
    if (recommendedWizards.length > 0) {
      moveBackwards(ChooseWizard);
    } else {
      moveBackwards(NoMatchesStep);
    }
  }, [recommendedWizards]);
  useFlowOptions(active, {
    error: submissionError ?? error,
    forwardDisabled: loading,
    onBackwards: handleBack,
    onForwards: handleForwards,
  });

  return (
    <Stack height="100%">
      <Text>{t("main:questionFlow.reformulateQuestion.description")}</Text>
      <Textarea
        required
        height="100%"
        minLength={MIN_QUESTION_LENGTH}
        placeholder={
          t("main:questionFlow.reformulateQuestion.input.placeholder")!
        }
        ref={ref}
        value={volunteerQuestion}
        onChange={(e) => setVolunteerQuestion(e.target.value)}
      />
    </Stack>
  );
};

export default ReformulateQuestionStep;
