import {
  FormControl,
  FormLabel,
  Heading,
  Input,
  Stack,
  Text,
} from "@chakra-ui/react";
import { t } from "i18next";
import React, { FC, useCallback, useContext, useEffect, useState } from "react";

import { QuestionFlowContext } from "../../../../shared/lib/questionFlowContext";
import { useSetName } from "../../../lib/auth";
import useCreateQuestion from "../hooks/useCreateQuestion";
import useDelayedFocus from "../hooks/useDelayedFocus";
import useFlowOptions from "../hooks/useFlowOptions";
import { StepRendererProps } from "../QuestionFlow";

import ReformulateQuestionStep from "./ReformulateQuestionStep";
import SendToGuideStep from "./SendToGuideStep";

const EnterNameStep: FC<StepRendererProps> = ({ active }) => {
  const { moveBackwards, wizardActive } = useContext(QuestionFlowContext);
  const [submitQuestion, loadingQuestion, questionError] = useCreateQuestion();
  const [firstName, setFirstName] = useState("");
  const ref = useDelayedFocus<HTMLInputElement>(active);
  const [handleSubmit, loading, error] = useSetName(firstName, submitQuestion);

  const questionStep = wizardActive ? SendToGuideStep : ReformulateQuestionStep;
  const handleBackwards = useCallback(
    () => moveBackwards(questionStep),
    [questionStep]
  );
  useFlowOptions(active, {
    error,
    forwardDisabled: loadingQuestion || loading,
    onBackwards: handleBackwards,
    onForwards: handleSubmit,
  });

  useEffect(() => {
    if (questionError) {
      moveBackwards(questionStep, { error: questionError });
    }
  }, [questionError]);

  return (
    <Stack height="100%">
      <Heading as="h3" marginBottom="1em" size="sm">
        {t("main:questionFlow.enterNameStep.header")}
      </Heading>
      <Text>{t("main:questionFlow.enterNameStep.description")}</Text>
      <FormControl>
        <FormLabel fontWeight="bold">
          {t("main:questionFlow.enterNameStep.inputs.label")}
        </FormLabel>
        <Input
          required
          minLength={2}
          placeholder={t("main:questionFlow.enterNameStep.inputs.placeholder")!}
          ref={ref}
          value={firstName}
          onChange={(e) => setFirstName(e.target.value)}
        />
      </FormControl>
    </Stack>
  );
};

export default EnterNameStep;
