import {
  Box,
  Card,
  CardBody,
  CardHeader,
  Container,
  IconButton,
  Stack,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react";
import { t } from "i18next";
import React, { FC, useContext, useEffect, useState } from "react";
import { FiMessageCircle, FiX } from "react-icons/fi";
import { RemoveScroll } from "react-remove-scroll";

import StatemachineContextProvider from "../../../shared/components/statemachine/contexts/StatemachineContext";
import { trackEvent } from "../../../shared/lib/analytics";
import { QuestionFlowContext } from "../../../shared/lib/questionFlowContext";
import { JourneyResource } from "../../../types/serializers";
import useVisualViewport from "../../hooks/useVisualViewport";

import { FlowDirection, StepProps } from "./QuestionFlowProvider";
import QuestionReceivedStep from "./steps/QuestionReceivedStep";
import SendToGuideStep from "./steps/SendToGuideStep";
import WizardStep from "./steps/WizardStep";
import StepWrapper from "./StepWrapper";

const TRANSITION_TIMEOUT = 500;

export interface StepRendererProps {
  active?: true;
}
export interface QuestionFlowProps {
  currentStep: FC<StepRendererProps>;
  currentStepProps: StepProps;
  direction: FlowDirection;
  initialJourneyId?: string;
  iteration: number;
  previousStep?: FC;
  previousStepProps: StepProps;
}

const QuestionFlow: FC<QuestionFlowProps> = ({
  currentStep: CurrentStep,
  currentStepProps,
  direction,
  initialJourneyId,
  iteration,
  previousStep: PreviousStep,
  previousStepProps,
}) => {
  const {
    open,
    openFlow,
    currentWizardId,
    closeFlow,
    moveForwards,
    referer,
    setCurrentWizardId,
    setInitialQuestion,
    setVolunteerQuestion,
    setWizardActive,
    showFloat,
    statemachineContext,
    widget,
    wizardActive,
  } = useContext(QuestionFlowContext);
  const { bottom, height } = useVisualViewport();
  const [disableHeightTransition, setDisableHeightTransition] = useState<
    number | undefined
  >(undefined);
  const removeScroll = useBreakpointValue({ base: true, md: false });
  const handleNewJourney = (newJourney: JourneyResource) => {
    if (wizardActive) {
      setInitialQuestion("");
    }
    setCurrentWizardId(newJourney.attributes.wizard_id.toString());
    setWizardActive(true);
    moveForwards(WizardStep, { stepIndex: 1 });
  };
  const handleDiscussResult = () => {
    setVolunteerQuestion("");
    moveForwards(SendToGuideStep);
  };
  const handleHeaderClick = () => {
    if (!open) {
      trackEvent("click_question_float_button");
      openFlow({ referer: "stickyButton" });
    }
  };
  useEffect(() => {
    const timestamp = new Date().getTime();
    setDisableHeightTransition(timestamp);
    setTimeout(() => {
      setDisableHeightTransition((currentTime) =>
        currentTime === timestamp ? undefined : currentTime
      );
    }, TRANSITION_TIMEOUT);
  }, [height]);

  // eslint-disable-next-line @typescript-eslint/no-magic-numbers
  const paddingTop = widget ? 0 : 5;

  return (
    <StatemachineContextProvider
      context={statemachineContext}
      id={currentWizardId ? parseInt(currentWizardId) : undefined}
      initialJourneyId={initialJourneyId}
      referer={referer}
      onDiscussResult={handleDiscussResult}
      onNewJourney={handleNewJourney}
    >
      <RemoveScroll enabled={removeScroll && open}>
        <Box
          aria-hidden={!open}
          aria-label={t("main:questionFlow.header")!}
          bottom="-35em"
          display={showFloat || open ? "block" : "none"}
          left={0}
          position="fixed"
          role="dialog"
          width="100%"
          zIndex={10}
        >
          <Container maxWidth="200em" padding={widget ? 0 : undefined}>
            <Card
              borderRadius={widget ? 0 : undefined}
              bottom={open ? `calc(35em + ${bottom}px)` : "34em"}
              boxShadow={open ? "dark-lg" : "white"}
              cursor={open ? undefined : "pointer"}
              float="right"
              height={
                open
                  ? {
                      base: `calc(${height}px - ${paddingTop}em)`,
                      md: `min(35em, calc(${height}px - 4em))`,
                    }
                  : "4em"
              }
              overflow="hidden"
              transition={`box-shadow .2s, width .2s${
                disableHeightTransition ? "" : ", height .2s"
              }`}
              width={open ? { base: "100%", md: "20em" } : "11em"}
              onClick={handleHeaderClick}
            >
              <CardHeader
                backgroundColor={open ? "primary.dark" : "primary.darkActive"}
                color="white"
                paddingY={3}
                transition="background-color .2s"
              >
                <Stack
                  alignItems="center"
                  direction="row"
                  display={open ? "flex" : "inline-flex"}
                  justifyContent="space-between"
                >
                  {!open && <FiMessageCircle />}
                  <Text fontWeight={700}>{t("main:questionFlow.header")}</Text>
                  {open && (
                    <IconButton
                      aria-label={t("main:questionFlow.close")}
                      colorScheme="white"
                      icon={<FiX />}
                      variant="ghost"
                      onClick={() => {
                        if (!widget) {
                          closeFlow(CurrentStep === QuestionReceivedStep);
                        }
                      }}
                    />
                  )}
                </Stack>
              </CardHeader>
              <CardBody position="relative">
                <Box height="100%" position="relative">
                  {PreviousStep && (
                    <StepWrapper
                      out
                      initialOut={iteration === 0}
                      key={`previous-${iteration}`}
                      sign={direction === FlowDirection.Forwards ? "-" : "+"}
                    >
                      <PreviousStep {...previousStepProps} />
                    </StepWrapper>
                  )}
                  {open && CurrentStep && (
                    <StepWrapper
                      initialOut={iteration !== 0}
                      key={`current-${iteration}`}
                      out={false}
                      sign={direction === FlowDirection.Forwards ? "+" : "-"}
                    >
                      <CurrentStep {...currentStepProps} active />
                    </StepWrapper>
                  )}
                </Box>
              </CardBody>
            </Card>
          </Container>
        </Box>
      </RemoveScroll>
    </StatemachineContextProvider>
  );
};

export default QuestionFlow;
