import { Box, Heading, Icon, Stack, Text } from "@chakra-ui/react";
import { t } from "i18next";
import React, { FC } from "react";
import { FiCheck, FiClock } from "react-icons/fi";

import useFlowOptions from "../hooks/useFlowOptions";
import { StepRendererProps } from "../QuestionFlow";

const QuestionReceivedStep: FC<StepRendererProps> = ({ active }) => {
  useFlowOptions(active);

  return (
    <Box>
      <Heading as="h3" marginBottom="1em" size="sm">
        {t("main:questionFlow.questionReceived.header")}
      </Heading>
      <Stack alignItems="flex-start" direction="row" marginBottom="1em">
        <Icon
          as={FiCheck}
          background="status.success"
          borderRadius="full"
          color="white"
          flex="none"
          fontSize="1.5em"
          padding=".1em"
        />
        <Text>{t("main:questionFlow.questionReceived.step1")}</Text>
      </Stack>
      <Stack alignItems="flex-start" direction="row" marginBottom="1em">
        <Icon
          as={FiClock}
          background="background.accordion"
          borderRadius="full"
          color="secondary.dark"
          flex="none"
          fontSize="1.5em"
          padding=".15em"
        />
        <Text>{t("main:questionFlow.questionReceived.step2")}</Text>
      </Stack>
    </Box>
  );
};

export default QuestionReceivedStep;
