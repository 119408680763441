import { Stack } from "@chakra-ui/react";
import React, { FC, useCallback, useContext } from "react";

import Action from "../../../../shared/components/statemachine/components/Action";
import Message from "../../../../shared/components/statemachine/components/Message";
import { StatemachineContext } from "../../../../shared/components/statemachine/contexts/StatemachineContext";
import { QuestionFlowContext } from "../../../../shared/lib/questionFlowContext";
import useFlowOptions from "../hooks/useFlowOptions";
import { StepRendererProps } from "../QuestionFlow";

import ChooseWizard from "./ChooseWizardStep";

const WizardStep: FC<
  StepRendererProps & {
    stepIndex: number;
  }
> = ({ active, stepIndex }) => {
  const { moveBackwards, moveForwards, initialQuestion, setWizardActive } =
    useContext(QuestionFlowContext);
  const { currentJourney, getStep } = useContext(StatemachineContext);

  const currentStep = getStep(stepIndex);
  const handleSuccess = () => {
    moveForwards(WizardStep, { stepIndex: stepIndex + 1 });
  };
  const handleBack = useCallback(() => {
    if (stepIndex > 1) {
      moveBackwards(WizardStep, { stepIndex: stepIndex - 1 });
    } else {
      setWizardActive(false);
      moveBackwards(ChooseWizard);
    }
  }, [stepIndex]);
  const handleForward = useCallback(
    () => moveForwards(WizardStep, { stepIndex: stepIndex + 1 }),
    [stepIndex]
  );
  const currentSteps = currentJourney?.relationships.current_steps.data ?? [];
  const hasForwardButton = stepIndex < currentSteps.length - 1;
  const hasBackwardButton = stepIndex > 1 || initialQuestion;
  useFlowOptions(active, {
    onBackwards: hasBackwardButton ? handleBack : undefined,
    onForwards: hasForwardButton ? handleForward : undefined,
    renderAsForm: false,
  });

  if (!currentStep) {
    return null;
  }

  return (
    <Stack gap="1em" height="100%" overflow="auto">
      <Message key={`message-${currentStep.id}`} step={currentStep} />
      <Action
        key={`action-${currentStep.id}`}
        step={currentStep}
        onSuccess={handleSuccess}
      />
    </Stack>
  );
};

export default WizardStep;
